import { ref, computed, markRaw } from 'vue'

// Cells
import PolicySetting from '../components/Grid/Cell/SitePage/PolicySetting.vue'
import PolicySettingHeader from '../components/Grid/Cell/SitePage/PolicySettingHeader.vue'
import Operation from '../components/Grid/Cell/SitePage/Operation.vue'
import OperationHeader from '../components/Grid/Cell/SitePage/OperationHeader.vue'
import ProbeIP from '../components/Grid/Cell/SitePage/ProbeIP.vue'
import BlockMsgState from '../components/Grid/Cell/SitePage/BlockMsgState.vue'

// 區域列表中使用 Kendo Grid
const useGridInSite = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)

  // Header 欄位
  const columns = ref([
    {
      field: 'SiteName',
      title: '區域名稱',
      width: '183px'
    },
    {
      field: 'PolicySetting',
      title: '政策設定',
      width: '145px',
      cell: markRaw(PolicySetting),
      headerCell: markRaw(PolicySettingHeader)
    },
    {
      field: 'Operation',
      title: '操作',
      width: '117px',
      cell: markRaw(Operation),
      headerCell: markRaw(OperationHeader)
    },
    {
      field: 'ProbeID',
      title: 'Probe ID',
      width: '133px'
    },
    {
      field: 'ProbeIP',
      title: 'Probe IP',
      width: '145px',
      cell: markRaw(ProbeIP)
    },
    {
      field: 'BlockMsgState',
      title: '封鎖訊息狀態',
      width: 'auto',
      cell: markRaw(BlockMsgState)
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    return dataItemsWithPage
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const siteName = [
      'Taipei Office',
      'San Jose Office',
      'Brunch',
      '資訊處 (14)',
      'Default Site'
    ]
    const operation = [
      { isDeletable: true },
      { isDeletable: true },
      { isDeletable: false }
    ]
    const probeID = ['', '主要探針']
    const probeIP = ['', '192.168.111.1111']
    const blockMsgState = [
      [],
      ['封鎖訊息'],
      ['AD 驗證', '訪客登入：1 小時', '兩階段驗證', '員工登入']
    ]
    // 已開啟 IPv4 安全模式, null 為 disable
    const isIpv4SafeModeEnabled = [true, false, null, null]
    // 已開啟 MAC 安全模式
    const isMacSafeModeEnabled = [true, false]
    // 已開啟超出管理範圍封鎖
    const isOutOfRangeBlockEnabled = [true, false]
    // 已開啟監看模式
    const isMonitorEnabled = [true, false]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        SiteName: siteName[Math.floor(Math.random() * siteName.length)],
        PolicySetting: '',
        Operation: operation[Math.floor(Math.random() * operation.length)],
        ProbeID: probeID[Math.floor(Math.random() * probeID.length)],
        ProbeIP: probeIP[Math.floor(Math.random() * probeIP.length)],
        BlockMsgState:
          blockMsgState[Math.floor(Math.random() * blockMsgState.length)],
        IsIpv4SafeModeEnabled:
          isIpv4SafeModeEnabled[
          Math.floor(Math.random() * isIpv4SafeModeEnabled.length)
          ],
        IsMacSafeModeEnabled:
          isMacSafeModeEnabled[
          Math.floor(Math.random() * isMacSafeModeEnabled.length)
          ],
        IsOutOfRangeBlockEnabled:
          isOutOfRangeBlockEnabled[
          Math.floor(Math.random() * isOutOfRangeBlockEnabled.length)
          ],
        IsMonitorEnabled:
          isMonitorEnabled[Math.floor(Math.random() * isMonitorEnabled.length)]
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    createRandomData,
    pageChangeHandler
  }
}

export default useGridInSite
