<template>
  <td class="policy-setting-cell" :data-id="dataItem.Id">
    <template v-for="(policyName, idx) in policyList" :key="idx">
      <KendoTooltip
        id="tooltip"
        :title="getTooltips(policyName, dataItem[policyName])"
        :position="'left'"
      >
        <div
          class="setting-btn"
          :class="{
            'is-active': dataItem[policyName],
            'is-disabled': dataItem[policyName] === null
          }"
        >
          <SvgIcon :icon="iconNames[policyName]" />
        </div>
      </KendoTooltip>
    </template>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    // 所有政策設定 field name
    const policyList = [
      'IsIpv4SafeModeEnabled',
      'IsMacSafeModeEnabled',
      'IsOutOfRangeBlockEnabled',
      'IsMonitorEnabled'
    ]
    // icon 對應
    const iconNames = {
      IsIpv4SafeModeEnabled: 'safe',
      IsMacSafeModeEnabled: 'mac-safe',
      IsOutOfRangeBlockEnabled: 'range-block',
      IsMonitorEnabled: 'monitor'
    }
    // tootip 敘述對應
    const tooltips = {
      IsIpv4SafeModeEnabled: 'IPv4 安全模式',
      IsMacSafeModeEnabled: 'MAC 安全模式',
      IsOutOfRangeBlockEnabled: '超出管理範圍封鎖',
      IsMonitorEnabled: '監看模式'
    }
    // 取得 tooltips
    const getTooltips = (policyName, active) => {
      const prefix = active ? '點擊以關閉' : '點擊已開啟'
      const term = tooltips[policyName]

      if (active !== null) {
        return prefix + term
      } else {
        // FIXME: 需補上所有按鈕禁用時的完整 tooltips 敘述
        return '請先關閉該區域底下的 DHCP 服務'
      }
    }
    return {
      policyList,
      iconNames,
      getTooltips
    }
  }
})
</script>

<style scoped>
.policy-setting-cell {
  text-align: right;
}
</style>
