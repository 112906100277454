// 區域列表

// 1. 篩選相關
const checkboxOptions = [
  { id: 'IsIpv4SafeModeEnabled', name: '已開啟 IPv4 安全模式', divider: false },
  {
    id: 'IsMacSafeModeEnabled',
    name: '已開啟 MAC 安全模式',
    divider: false
  },
  {
    id: 'IsOutOfRangeBlockEnabled',
    name: '已開啟超出管理範圍封鎖',
    divider: false
  },
  { id: 'IsMonitorEnabled', name: '已開啟監看模式', divider: false }
]
// 起始的勾選 checkbox 選項
const initialCheckedIds = ['IsIpv4SafeModeEnabled', 'IsMacSafeModeEnabled']

// 可用的篩選條件：FilterUnitAdd.vue 元件
const keyWithOptions = [
  { key: '區域名稱', options: [] },
  { key: 'Probe IP', options: [] },
  { key: 'Probe 名稱', options: [] }
]

// 起始的篩選組合：FilterUnitDeletable.vue 元件
const initialFilterUnitList = [
  {
    id: 0,
    key: 'Probe IP',
    operator: '!=',
    value: '123',
    options: [] // 值沒有選項，會渲染文字輸入框
  }
]

export {
  checkboxOptions,
  initialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
}
