
import { defineComponent, ref, onMounted } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitCheckbox from '@/components/Filter/FilterUnitCheckbox.vue'
import useFilterCheckbox from '@/composable/useFilterCheckbox'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import usGridInSite from '@/composable/useGridInSite'

import {
  checkboxOptions,
  initialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/site'

export default defineComponent({
  components: {
    Tabs,
    Tab,
    ControlWrapper,
    ControlButton,
    FilterWrapper,
    FilterTextInput,
    FilterUnitCheckbox,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo
  },
  setup() {
    // 篩選相關
    // 文字輸入
    const textInput = ref('')
    // checkbox 篩選
    const { allChecked, checkedIds, onCheck, checkAll } = useFilterCheckbox(
      checkboxOptions,
      initialCheckedIds
    )

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('checkedIds: ' + checkedIds.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關：
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = usGridInSite()

    onMounted(() => {
      dataItems.value = createRandomData(16) as never[]
    })

    return {
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：多選選項
      checkboxOptions,
      checkedIds,
      allChecked,
      onCheck,
      checkAll,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    }
  }
})
