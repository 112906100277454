<template>
  <h2 class="build-env-title">建置環境</h2>

  <Tabs>
    <Tab pathName="Site" title="區域列表" />
    <Tab pathName="Network" title="網段列表" />
    <Tab pathName="Probe" title="Probe 列表" />
  </Tabs>

  <div class="main-list-view site-list-view">
    <!-- 列表容器 -->
    <div class="main-list-container">
      <!-- 上：控制列容器 -->
      <ControlWrapper>
        <ControlButton iconName="plus" text="新增區域" />
      </ControlWrapper>
      <!-- 中：篩選列容器 -->
      <FilterWrapper>
        <FilterTextInput
          :placeholder="'查詢區域 / Probe'"
          v-model.trim="textInput"
          @submitFilter="submitFilter"
        />
        <FilterUnitCheckbox
          :allChecked="allChecked"
          :checkedIds="checkedIds"
          :keyName="'政策設定'"
        >
          <label class="base-checkbox filter-checkbox">
            <input type="checkbox" v-model="allChecked" @change="checkAll" />
            <div class="custom-checkbox"></div>
            <span>全部</span>
          </label>
          <div class="divider"></div>
          <template v-for="option in checkboxOptions" :key="option.id">
            <label class="base-checkbox filter-checkbox">
              <input
                type="checkbox"
                :value="option.id"
                v-model="checkedIds"
                @change="onCheck"
              />
              <div class="custom-checkbox"></div>
              <span>{{ option.name }}</span>
            </label>
            <div class="divider" v-if="option.divider"></div>
          </template>
        </FilterUnitCheckbox>
        <FilterUnitDeletable
          v-for="filterUnit in filterUnitList"
          :filterUnit="filterUnit"
          :key="filterUnit.key"
          @updateFilterUnit="updateFilterUnit"
          @deleteFilterUnit="deleteFilterUnit"
        />
        <FilterUnitAdd
          :keyWithOptions="keyWithOptions"
          @addFilterUnit="addFilterUnit"
        />
        <div class="spacer"></div>
      </FilterWrapper>
      <!-- 下：表格容器 -->
      <GridWrapper>
        <GridPageInfo :skip="skip" :take="take" :total="total" />
        <Grid
          :style="{ height: 'calc(100vh - 300px)' }"
          :columns="columns"
          :data-items="result"
          :pageable="pageable"
          :skip="skip"
          :take="take"
          :total="total"
          @pagechange="onPageChange"
          :scrollable="'true'"
          :sortable="false"
        ></Grid>
      </GridWrapper>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitCheckbox from '@/components/Filter/FilterUnitCheckbox.vue'
import useFilterCheckbox from '@/composable/useFilterCheckbox'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import usGridInSite from '@/composable/useGridInSite'

import {
  checkboxOptions,
  initialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/site'

export default defineComponent({
  components: {
    Tabs,
    Tab,
    ControlWrapper,
    ControlButton,
    FilterWrapper,
    FilterTextInput,
    FilterUnitCheckbox,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo
  },
  setup() {
    // 篩選相關
    // 文字輸入
    const textInput = ref('')
    // checkbox 篩選
    const { allChecked, checkedIds, onCheck, checkAll } = useFilterCheckbox(
      checkboxOptions,
      initialCheckedIds
    )

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('checkedIds: ' + checkedIds.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關：
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = usGridInSite()

    onMounted(() => {
      dataItems.value = createRandomData(16) as never[]
    })

    return {
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：多選選項
      checkboxOptions,
      checkedIds,
      allChecked,
      onCheck,
      checkAll,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    }
  }
})
</script>

<style scoped></style>
